import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  modalTitle,
  children,
  isOpen,
  ModalClose,
  buttonTitle,
  buttonAction,
  handleSubmit,
  disabled,
  customValue,
}) {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.vimeo);
  const handleClose = () => {
    ModalClose(false);
  };

  useEffect(() => {
    if (!loading) {
      ModalClose(false);
    }
  }, [loading]);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <form onSubmit={handleSubmit(buttonAction)}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {modalTitle}
          </DialogTitle>
          <DialogContent dividers>{children}</DialogContent>
          <DialogActions>
          <span>
          {console.log(disabled)}
          </span> 
          {!disabled && (
            <Button autoFocus color="primary" type="submit">
              {buttonTitle}
            </Button>
            )}
          </DialogActions>
          {loading && (
            <div className={classes.loading}>
              <CircularProgress size={60} />
            </div>
          )}
        </form>
      </Dialog>
    </div>
  );
}
