import { Box, Grid, Tab, Tabs, Typography, TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import CoursesSlider from 'components/CoursesSlider';
import ProgressCourseSlider from 'components/ProgressCourseSlider';
import ProgressTrailSlider from 'components/ProgressTrailSlider';
import { TabPanel } from 'components/TabPanel';
import TrailsSlider from 'components/TrailsSlider';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CourseData, LessonData, TrailData } from 'services/dataService/index';
import history from 'services/history';
import { StudentContentHeader } from 'views/StudentContent/components';
import enablesCompaniesArray from '../../../helpers/enablesCompanies';
import { EnrollmentData } from 'services/dataService';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import ModalPlatform from 'components/RatingPlatform/components/ModalPlatform/Index';
import { format } from 'date-fns';
import { RatingData } from 'services/dataService';
import CircularProgress from '@mui/material/CircularProgress';



export default () => {
  const classes = useStyles();
  const { company, auth } = useSelector((state) => state);
  const { id: userId } = auth;
  const { t } = useTranslation(['label', 'toastify', 'tables', 'menu', 'views']);
  const [courses, setCourses] = useState([]);
  const [finishedCourses, setFinishedCourses] = useState([]);
  const [startCourses, setStartCourses] = useState([]);
  const [trails, setTrails] = useState([]);
  const [finishedTrails, setFinishedTrails] = useState([]);
  const [startTrails, setStartTrails] = useState([]);
  const [tab, setTab] = useState(0);
  const [showTrailTab, setShowTrailTab] = useState(false);

  const [courseName, setCourseName] = React.useState(null);
  const [filteredCourses, setFilteredCourses] = React.useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseList, setCourseList] = React.useState([]);
  const [hideStartCourses, setHideStartCourses] = useState(true);
  const [hideCourses, setHideCourses] = useState(true);
  const [hideFinishedCourses, setHideFinishedCourses] = useState(true);

  const [trailName, setTrailName] = React.useState(null);
  const [filteredTrails, setFilteredTrails] = React.useState(null);
  const [selectedTrails, setSelectedTrails] = useState(null);
  const [trailList, setTrailList] = React.useState([]);
  const [hideStartTrails, setHideStartTrails] = useState(true);
  const [hideTrails, setHideTrails] = useState(true);
  const [hideFinishedTrails, setHideFinishedTrails] = useState(true);
  const [openRating, setOpenRating] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);


  const [error, setError] = React.useState(false);
  const [showElements, setShowElements] = React.useState(0)


  const CustomTab = withStyles({
    root: {
      border: '1px solid #176AE6',
      color: '#176AE6 !important',
      borderRadius: '4px',
      marginRight: '10px',
      marginBottom: 0,
    },
    selected: {
      backgroundColor: '#176AE6',
      color: '#fff !important',
    },
  })(Tab);

  useEffect(() => {
    // onShouldOpenModalRating();
    if (company?.domain_company) {
      const canShowTrailInSidebar = company?.show_trails
      setShowTrailTab(canShowTrailInSidebar ? true : false);
    }
  }, [company]);

  useEffect(() => {
    loadTrails();
    loadCourses();
  }, []);

  useEffect(() => {
    loadCoursesInProgress();
  }, [finishedCourses]);


  const loadCourses = async () => {
    try {
      const { data: coursesData } = await CourseData.getMyContentCourses(userId);
      const { finishedCourses } = coursesData;

      if (finishedCourses) {
        const sortedFinishedCourses = finishedCourses.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { ignorePunctuation: true, sensitivity: 'accent' })
        );
        setFinishedCourses(sortedFinishedCourses || []);
      }

    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const loadCoursesInProgress = async () => {
    try {
      const courseData = (await CourseData.getCourseInProgress(userId)).data;

      const progressZero = courseData
        ?.filter((course) => course?.course_progress < 1)
        ?.sort((a, b) => a.name.localeCompare(b.name, undefined, { ignorePunctuation: true, sensitivity: 'accent' }));

      const inProgress = courseData
        ?.filter((course) => course?.course_progress > 0)
        ?.sort((a, b) => (a.course_progress < b.course_progress ? 1 : -1));

      setCourses(inProgress || []);
      setStartCourses(progressZero || []);

      const updatedCourseList = [...finishedCourses, ...courseData];

      setCourseList(updatedCourseList);
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const loadTrails = async () => {
    try {
      setIsLoading(true);
      const trailData = (await TrailData.getTrailUser(userId)).data;

      const progressZero = trailData
        ?.filter((trail) => Number(trail?.progress) === 0)
        ?.sort((a, b) => a.name.localeCompare(b.name, undefined, { ignorePunctuation: true, sensitivity: 'accent' }));

      const inProgress = trailData
        ?.filter(
          (trail) =>
            Number(trail?.progress) > 0 && Number(trail?.progress) < 100
        )
        ?.sort((a, b) => (a.progress < b.progress ? 1 : -1));

      const finished = trailData
        ?.filter((trail) => Number(trail?.progress) === 100)
        ?.sort((a, b) => a.name.localeCompare(b.name, undefined, { ignorePunctuation: true, sensitivity: 'accent' }));

      setTrails(inProgress || []);
      setStartTrails(progressZero || []);
      setFinishedTrails(finished || []);

      const updatedTrailList = [...progressZero, ...inProgress, ...finished];
      setTrailList(updatedTrailList);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };


  const handleAutocompleteChangeTrails = (e, value) => {
    setTrailName(value ? value.name : "");

    if (value) {
      const filteredTrails = trailList.filter((trail) =>
        trail.name.includes(value.name)
      );

      const isFinishedTrail = finishedTrails.some(
        (course) => course.name === value.name
      );

      const isTrail = trails.some(
        (course) => course.name === value.name
      );

      const isStartTrail = startTrails.some(
        (course) => course.name === value.name
      );

      setFilteredTrails(filteredTrails);
      setSelectedTrails(value);

      setHideStartTrails(isStartTrail);
      setHideTrails(isTrail);
      setHideFinishedTrails(isFinishedTrail);
    } else {
      setFilteredTrails([]);
      setSelectedTrails(null);
      setHideStartTrails(true);
      setHideTrails(true);
      setHideFinishedTrails(true);
    }
  }


  const handleAutocompleteChange = (e, value) => {
    setCourseName(value ? value.name : "");
    if (value) {
      const filtered = courseList.filter((course) =>
        course.name.includes(value.name)
      );

      const isFinishedCourse = finishedCourses.some(
        (course) => course.name === value.name
      );

      const isCourse = courses.some(
        (course) => course.name === value.name
      );

      const isStartCourse = startCourses.some(
        (course) => course.name === value.name
      );

      setFilteredCourses(filtered);
      setSelectedCourse(value);

      setHideStartCourses(isStartCourse);
      setHideCourses(isCourse);
      setHideFinishedCourses(isFinishedCourse);

    } else {
      setFilteredCourses([]);
      setSelectedCourse(null);
      setHideCourses(true);
      setHideStartCourses(true);
      setHideFinishedCourses(true);
    }
  };



  const onActionClickGoToTrail = async (trailId, type, courseId = null) => {

    switch (type) {
      case 'progress':
        history.replace(
          `trail/${trailId}/learn?course=${courseId}&progress=true`
        );
        break;
      case 'finished':
        history.replace(
          `trail/${trailId}/learn?course=${courseId}&finished=true`
        );
        break;
      default:
        history.replace(`trail/${trailId}/learn`);
        break;
    }
  };

  const onActionClick = async (company_course) => {
    try {
      const { data: lesson } = await LessonData.getNextLesson(
        company_course,
        userId
      );

      let id = '';

      if (lesson?.length) {
        id = lesson[0]?.id;
      }

      history.replace(`/course/${company_course}/learn/${id || ''}`);
    } catch (error) {
      history.replace(`/course/${company_course}/learn`);
    }
  };

  const handleChangeTab = (_, newValue) => {
    setTab(newValue);
  };
  const handleEmpty = (type) => {
    return (
      <Box width="100%" className={classes.withoutContent}>
        <img src="/images/undraw_learning.svg" alt="Learning" />
        <div>
          <Typography className={classes.withoutContentText} variant="h1">
            {t('label:StudentContent.nothing_available')}
          </Typography>
          <Typography className={classes.withoutContentDescription}>
            {t('label:StudentContent.request_admin')}
          </Typography>
          <Link to={type == 'trilhas' ? "/available-content" : "/available-content"}>
            {type == 'trilhas' ?
              <span style={{ display: 'none' }}>{t('label:StudentContent.available_trails')}</span>
              :
              <span className={classes.link} >{t('label:StudentContent.available_content')}
                <ArrowForwardRoundedIcon />
              </span>
            }
          </Link>
        </div>
      </Box>
    )
  }

  // const onShouldOpenModalRating = async () => {
  //   try {
  //     const dateNow = format(new Date(), 'MM-yyyy');
  //     const response = await RatingData.hasRatingPlatform(dateNow, userId);
  //     if (response && response.data.shouldOpenModal) {
  //       setOpenRating(true)
  //     }
  //   } catch (error) {
  //     console.error('Erro ao verificar se o modal deve ser aberto:', error);
  //   }
  // };
  


  return (
    <div className='notranslate'>
      <Box className={classes.root}>
        {/* Intern Featured Courses */}
        {/* {openRating && (company?.domain_company !== '2a48df24-a717-4934-9513-56c036d2f4d3') && (
          <ModalPlatform
            setOpen={setOpenRating}
            company={company?.domain_company}
            user={userId}
          />
        )} */}
        <StudentContentHeader />


        <Grid item xs={12}>
          <Tabs
            value={tab}
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
            aria-label={t('tables:commons.tab')}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <CustomTab
              key={0}
              label={
                <span className={classes.tabItemsTitle}>
                  <FormatListBulletedIcon />
                  <span>{t('menu:sidebar.student.my_contents')}</span>
                </span>
              }
            />
            {showTrailTab && (
              <CustomTab
                key={1}
                label={
                  <span className={classes.tabItemsTitle}>
                    <CallSplitIcon />
                    <span>{t('label:StudentContent.my_trails')}</span>
                  </span>
                }
              />
            )}
          </Tabs>
          {/* Buscar de curso ou trilha aluno */}
          <Grid item md={8} xs={12}>
          {/* <Button
             className={classes.blink}
             style={{
                      backgroundColor: 'red',
                      color: 'white'
                    }}    
          >LIVE</Button> */}
            <Autocomplete
              fullWidth
              freeSolo
              clearOnEscape
              id="combo-box-demo"
              options={tab === 0 ? courseList : trailList}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                if (tab === 0) {
                  handleAutocompleteChange(event, value);
                } else if (tab === 1) {
                  handleAutocompleteChangeTrails(event, value);
                }
              }} onInput={() => {
                setError(false);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={error}
                  margin="normal"
                  label={t('form:Courses.input_content')}
                  variant="outlined"
                  helperText={error ? t('form:commons.no_options') : ''}
                />
              )}
            />
          </Grid>

          <TabPanel value={tab} index={0}>
            <Box className={classes.content}>
              {isLoading ? (
                <div className={classes.loading}>
                  <div>
                    <CircularProgress
                      className={classes.loadingIcon} />
                  </div>
                  <div>
                    Carregando Seus Conteúdos
                  </div>
                </div>
              ) : (
                <>
                  {(startCourses.length === 0 && courses.length === 0 && finishedCourses.length === 0) ? (
                    handleEmpty('conteudos')
                  ) : (
                    <>
                      {/* Courses to start */}
                      {startCourses.length > 0 && hideStartCourses && (
                        <Box width="100%" className={classes.bottomSpacing}>
                          {selectedCourse === null && (
                            <Typography className={classes.title} variant="h4">
                              {t('label:StudentContent.label_available_to_you')}
                            </Typography>
                          )}
                          <ProgressCourseSlider
                            onActionClick={onActionClick}
                            courses={selectedCourse ? startCourses.filter((course) => course.name === selectedCourse.name) : startCourses}
                          />
                        </Box>
                      )}
                      {courses.length > 0 && hideCourses && (
                        <Box width="100%" className={classes.bottomSpacing}>
                          {selectedCourse === null && (
                            <Typography className={classes.title} variant="h4">
                              {t('label:StudentContent.label_in_progress')}
                            </Typography>
                          )}
                          <ProgressCourseSlider
                            onActionClick={onActionClick}
                            courses={selectedCourse ? courses.filter((course) => course.name === selectedCourse.name) : courses}
                          />
                        </Box>
                      )}
                      {/* Finished Courses */}
                      {finishedCourses?.length > 0 && hideFinishedCourses && (
                        <Box width="100%" className={classes.bottomSpacing}>
                          {selectedCourse === null && (
                            <Typography className={classes.title} variant="h4">
                              {t('label:StudentContent.finished')}
                            </Typography>
                          )}
                          <CoursesSlider
                            finished
                            onActionClick={onActionClick}
                            courses={
                              selectedCourse
                                ? finishedCourses.filter((course) => course.name === selectedCourse.name)
                                : finishedCourses}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          </TabPanel>

          {showTrailTab && (
            <TabPanel value={tab} index={1}>
              <Box className={classes.content}>
                {isLoading ? (
                  <div className={classes.loading}>
                    <div>
                      <CircularProgress
                        className={classes.loadingIcon} />
                    </div>
                    <div>
                      Carregando Suas trilhas
                    </div>
                  </div>
                ) : (
                  <>
                    {(startTrails.length === 0 && trails.length === 0 && finishedTrails.length === 0) ? (
                      handleEmpty('trilhas')
                    ) : (
                      <>
                        {/* Trails to start */}
                        {startTrails.length > 0 && hideStartTrails && (
                          <Box width="100%" className={classes.bottomSpacing}>
                            {selectedTrails === null && (
                              <Typography className={classes.title} variant="h4">
                                {t('label:StudentContent.label_available_to_you')}
                              </Typography>
                            )}
                            <ProgressTrailSlider
                              onActionClick={onActionClick}
                              onActionClickGoToTrail={onActionClickGoToTrail}
                              trails={
                                selectedTrails
                                  ? startTrails.filter((trail) => trail.name === selectedTrails.name)
                                  : startTrails
                              }
                            />
                          </Box>
                        )}
                        {/* Trails in progress */}
                        {trails.length > 0 && hideTrails && (
                          <Box width="100%" className={classes.bottomSpacing}>
                            {selectedTrails === null && (
                              <Typography className={classes.title} variant="h4">
                                {t('label:StudentContent.label_in_progress')}
                              </Typography>
                            )}
                            <ProgressTrailSlider
                              onActionClickGoToTrail={onActionClickGoToTrail}
                              onActionClick={onActionClick}
                              trails={
                                selectedTrails
                                  ? trails.filter((trail) => trail.name === selectedTrails.name)
                                  : trails
                              }
                            />
                          </Box>
                        )}
                        {/* Finished Trails */}
                        {finishedTrails.length > 0 && hideFinishedTrails && (
                          <Box width="100%" className={classes.bottomSpacing}>
                            <>
                              {selectedTrails === null && (
                                <Typography className={classes.title} variant="h4">
                                  {t('label:StudentContent.finished')}
                                </Typography>
                              )}
                              <TrailsSlider
                                finished
                                onActionClickGoToTrail={onActionClickGoToTrail}
                                trails={
                                  selectedTrails
                                    ? finishedTrails.filter((trail) => trail.name === selectedTrails.name)
                                    : finishedTrails
                                }
                              />
                            </>
                          </Box>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </TabPanel>
          )}
        </Grid>
      </Box>
    </div>
  );
};
