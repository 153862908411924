import { Box, Button, LinearProgress, Typography } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import CustomSlider from 'components/CustomSlider';
import Interweave from 'interweave';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

/**
 *
 * @param {[{banner:string name:string description:string description:string liked_course:boolean id_company_course:string assigned: boolean}]} courses
 * @param {(id_company_course: string)=> {}} onLike
 * @param {(id_company_course: string)=> {}} onActionClick
 */
const CoursesSlider = ({
  finished,
  courses,
  onLike,
  onActionClick,
  slidesNumber,
  renderOnTrail,
  progress,
  isKnowledgeTrailPage,
  ...props
}) => {
  const { t } = useTranslation(['button']);
  const [slidesToShow, setSlidesToShow] = React.useState(4);
  const classes = useStyles();

  const sortElements = renderOnTrail
    ? (a, b) => (a?.order > b?.order ? 1 : -1)
    : (a, b) => (a.name > b.name ? 1 : -1);

  console.log(courses)

  //Função para marcar trilha como concluida

  // function isLastEnabledCourse(currentCourse) {
  //   const enabledCourses = courses.filter(course => course.enabled);
  //   return enabledCourses[enabledCourses.length - 1] === currentCourse;
  // }

  return (
    <CustomSlider
      itens={courses}
      slidesNumber={slidesNumber}
      slidesToShow={slidesToShow}
      setSlidesToShow={setSlidesToShow}
      slideTeste={renderOnTrail ? finished : true}
      {...props}
    >
      {courses?.sort(sortElements)?.map((course, index) => {
        return (
          <div key={index} className={classes.slidesShow}>
            <Box
              className={classes.card}
              style={{ width: slidesToShow <= 2 ? '100%' : 'unset' }}
            >
              <Box>
                {/* <div className={classes.topHeader}>
              { (course?.enabled) && (
                  <CheckCircleIcon className={classes.finishedIcon} /> )}
              </div> */}
                <Button
                  onClick={() => {
                    if (
                      onActionClick &&
                      renderOnTrail &&
                      course?.enabled
                    ) {
                      onActionClick(course?.id);
                      return;
                    }

                    if (
                      (!renderOnTrail && onActionClick && course?.active) ||
                      (!renderOnTrail && onActionClick && !course?.assigned) ||
                      (!renderOnTrail && onActionClick && finished)
                    ) {
                      onActionClick(
                        course?.assigned
                          ? course?.id
                          : course?.id_company_course,
                        course?.assigned
                      );
                    }
                  }}
                >
                  {/* 
                 Checagem das trilhas e adição da imagem
                 <div className='card'>
                    <img
                      className={`${classes.media} ${course?.enabled && !isLastEnabledCourse(course) ? classes.blurredMedia : ''}`}
                      src={course.banner ? course.banner : 'https://via.placeholder.com/350x208'}
                    />
                    <div className={classes.topHeader}>
                      {course?.enabled && course?.order && !isLastEnabledCourse(course) && (
                        <img
                          className={classes.imgCheck}
                          src={"/images/checkTrails/check_trails.png"}
                          alt="check_trails"
                        />
                      )}
                    </div>
                  </div> */}
                  <img
                    className={isKnowledgeTrailPage ? classes.mediaKnowledgeTrail : classes.media}
                    src={
                      course.banner
                        ? course.banner
                        : 'https://via.placeholder.com/350x208'
                    }
                  />

                </Button>
                <Box className={classes.cardBody}>
                  <Typography component="span" className={classes.header}>
                    {course.name}
                  </Typography>
                  <Box className={classes.description}>
                    <Interweave
                      content={
                        course?.description?.length ? course?.description : '-'
                      }
                    />
                  </Box>
                  <Box className={classes.action}>
                    {renderOnTrail && (
                      <Box className={classes.accessButton}>
                        <Button
                          size="medium"
                          color="primary"
                          className={classes.actionButton}
                          disabled={!course?.enabled}
                          onClick={() => {
                            if (onActionClick && progress) {
                              onActionClick(course?.id);
                            }
                          }}
                        >
                          {t('button:StudentContent.btn_start')}
                        </Button>
                      </Box>
                    )}
                    {!renderOnTrail && (
                      <Box className={classes.accessButton}>
                        <Button
                          size="medium"
                          color="primary"
                          className={classes.actionButton}
                          disabled={
                            !finished && course?.assigned && !course?.active
                          }
                          onClick={() => {
                            if (onActionClick) {
                              onActionClick(
                                course?.assigned
                                  ? course?.id
                                  : course?.id_company_course,
                                course?.assigned
                              );
                            }
                          }}
                        >
                          {finished && t(`button:StudentContent.btn_completed`)}
                          {!finished &&
                            t(
                              `button:StudentContent.${course?.assigned && course?.active
                                ? 'btn_start'
                                : course?.assigned && !course?.active
                                  ? 'btn_block'
                                  : 'btn_enroll'
                              }`
                            )}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        );
      })}
    </CustomSlider>
  );
};
export default CoursesSlider;