import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Switch,
  TextField,
  Typography as MuiTypography,
  Typography
} from '@material-ui/core';
import {
  Apps,
  Assignment,
  AssignmentTurnedIn,
  AttachFile,
  Description,
  Duo,
  FileCopyOutlined,
  Folder,
  HeadsetMic,
  InfoOutlined,
  MenuBook,
  MoreVert,
  OndemandVideo,
  PictureAsPdf,
  Theaters,
  Visibility,
  WebAsset,
  FolderOpen,
} from '@material-ui/icons';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { HandleDelete } from 'components/';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LessonContentData } from 'services/dataService';
import {
  uploadEmbedSuccess,
  uploadFileSuccess,
  uploadHtmlSuccess,
  uploadRequest,
  vimeoClearURL,
  vimeoRequest,
  uploadEmbedInteractSuccess
} from 'store/modules/content/actions';
import UploadModal from '../../../../components/UploadModal';
import { ContentCard } from '../index';
import { useStyles } from './styles';

export default (props) => {
  const { id: company_course_id } = useParams();

  const dispatch = useDispatch();
  const {
    url_vimeo,
    titleUpload,
    descriptionUpload,
    id_file,
    html_content,
    embed_content,
  } = useSelector((state) => state.vimeo);

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['tables', 'label', 'toastify', 'message', 'form']);
  const { provided, snapshot, lesson, modules, setModules, is_owner } = props;
  const [lessonContents, setLessonContents] = useState([]);
  const [currentlessonContent, setCurrentLessonContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [menuLesson, setMenuLesson] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [htmlModal, setHtmlModal] = useState(false);
  const [audioContentModal, setAudioContentModal] = useState(false);
  const [embedContentModal, setEmbedContentModal] = useState(false);
  const [attach, setAttach] = useState(null);
  const [preloadURL, setPreloadURL] = useState(null);
  const [contentHTML, setContentHTML] = useState();
  const [lessonUpdate, setLessonUpdate] = useState(null);
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const [check, setCheck] = useState(true);
  const [allowPdfDownload, setAllowPdfDownload] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [interactiveContentModal, setInteractiveContentModal] = useState(false);
  const [CustomValue, setCustomValue] = useState(false);

  const handleIconClick = () => {
    setCustomValue(true);
  };

  console.log('custom value ', CustomValue)

  const handleChangeAllowPdfDownload = (event) => {
    setAllowPdfDownload(event.target.checked);
  };

  const handleChange = (event) => {
    setCheck(event.target.checked);
  };

  const handleAssessmentRoute = (lesson) => {
    history.push(`/lesson-exam/new/${lesson.id_lesson}`);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#2DCE98' : '#F5F5F5',
    ...draggableStyle,
  });

  function handleCopyExSite() {
    navigator.clipboard.writeText(
      '<iframe src="seu-link-aqui-dentro"></iframe>'
    );
    toast.success(t('toastify:commons.toast_copied'));
  }

  function functionDelete() {
    const arrayModules = modules.map((module) => {
      return module.filter((less) => less.id_lesson != lesson.id_lesson);
    });
    setModules(arrayModules);
  }

  async function functionContentDelete(id) {
    try {
      await LessonContentData.deleteLessonContent(id);
      const arrayLessonContents = lessonContents.filter((content) => {
        return content.id !== id;
      });
      setLessonContents(arrayLessonContents);
    } catch (err) {
      toast.error(t('toastify:commons.toast_error'));
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenVideo = (currentLesson) => {
    setCurrentLessonContent(currentLesson);
    setVideoModal(true);
  };

  const handleOpenFile = (currentLesson) => {
    setCurrentLessonContent(currentLesson);
    setFileModal(true);
  };

  const handleOpenHtmlContent = (currentLesson) => {
    setCurrentLessonContent(currentLesson);
    setHtmlModal(true);
  };

  const handleOpenEmbedContent = (currentLesson) => {
    setCurrentLessonContent(currentLesson);
    setEmbedContentModal(true);
  };

  const handleOpenEmbedInteractContent = (currentLesson) => {
    setCurrentLessonContent(currentLesson);
    setInteractiveContentModal(true);
  };

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
      currentlessonContent,
    };

    if (type.startsWith('video/')) return <Theaters {...iconProps} />;
    if (type.startsWith('audio/')) return <AttachFile {...iconProps} />;

    switch (type) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <Description {...iconProps} />;
      case 'application/pdf':
        return <PictureAsPdf {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };

  const fileUploadVimeo = async (source_data) => {
    try {
      if (!preloadURL && !lessonUpdate) {
        toast.error(t('form:Lessons.attach_file'));
        return;
      }

      const file = attach ? attach[0] : null;

      const data = {
        name: source_data.title,
        description: source_data.description,
      };

      await dispatch(vimeoRequest(data, file));

      setPreloadURL(null);
    } catch (err) {
      toast.error(t('toastify:commons.toast_error'));
    }
  };

  const fileUpload = async (data) => {
    try {
      const formData = new FormData();
      if (attach && attach.length > 0) {
        formData.append('file', attach[0]);
      }
      formData.append('name', data.title);
      formData.append('description', data.description);
      await dispatch(
        uploadRequest(formData, currentlessonContent, company_course_id)
      );
    } catch (err) {
      toast.error(t('toastify:commons.toast_error'));
    }
  };

  const htmlContentUpload = async (data) => {
    try {
      const content = draftToHtml(
        convertToRaw(contentHTML.getCurrentContent())
      );
      dispatch(uploadHtmlSuccess(content, data.title, data.description));
      setHtmlModal(false);
      setContentHTML(null);
    } catch (err) {
      toast.error(t('toastify:commons.toast_error'));
    }
  };

  const audioContentUpload = async (data) => {
    try {
      const formData = new FormData();
      formData.append('file', attach[0]);
      formData.append('name', data.title);
      formData.append('description', data.description);
      await dispatch(
        uploadRequest(formData, currentlessonContent, company_course_id)
      );
    } catch (err) {
      toast.error(t('toastify:commons.toast_error'));
    }
  };

  const embedContentUpload = async (data) => {
    try {
      await dispatch(
        uploadEmbedSuccess(data.url, data.title, data.description)
      );
      setEmbedContentModal(false);
    } catch (err) {
      toast.error(t('toastify:commons.toast_error'));
    }
  };

  const embedContentInteractUpload = async (data) => {
    try {
      await dispatch(
        uploadEmbedInteractSuccess(data.url, data.title, data.description)
      );
      setInteractiveContentModal(false);
    } catch (err) {
      toast.error(t('toastify:commons.toast_error'));
    }
  };

  const addContent = async (type) => {
    handleClose();

    console.log('type: ', type)

    try {
      if (
        url_vimeo?.toLocaleLowerCase() === 'not updated'.toLocaleLowerCase()
      ) {
        await dispatch(vimeoClearURL());
      }

      if (id_file?.toLocaleLowerCase() === 'not updated'.toLocaleLowerCase()) {
        await dispatch(
          uploadFileSuccess(undefined, titleUpload, descriptionUpload)
        );
      }

      if (lessonUpdate) {
        const payload = {
          id_lesson: currentlessonContent,
          type,
          url_vimeo,
          id_file,
          name: titleUpload,
          description: descriptionUpload,
          content: html_content || embed_content,
          allow_download: allowPdfDownload,
        };
        Object.keys(payload).forEach((key) => {
          if (!payload[key] && key !== 'allow_download') {
            delete payload[key];
          }
        });
        await LessonContentData.updateLessonContent(lessonUpdate.id, payload);
      } else {
        await LessonContentData.addLessonContent({
          id_lesson: currentlessonContent,
          type,
          url_vimeo,
          id_file,
          name: titleUpload,
          description: descriptionUpload,
          content: html_content || embed_content,
          allow_download: allowPdfDownload,
        });
      }
      await getLessonContentData();
      toast.success(t('toastify:commons.toast_success'));
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else if (err.response?.status === 405) {
        // toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  useEffect(() => {
    if (!currentlessonContent) return;
    try {
      if (url_vimeo) {
        addContent(1);
      }

      if (id_file) {
        addContent(2);
      }

      if (html_content) {
        addContent(3);
      }

      if (embed_content && !CustomValue) {
        addContent(8);
      }

      if (embed_content && CustomValue) {
        addContent(9);
      }
    } catch (err) {
      toast.error(t('toastify:commons.toast_error'));
    }
  }, [url_vimeo, id_file, html_content, embed_content, CustomValue]);

  const handleChangeVimeo = (files) => {
    if (files.length > 0) {
      const fileName = files[0].name;
      if (fileName.length > 50 && files[0].type !== 'video/mp4') {
        toast.error(t('O nome do arquivo não pode ter mais de 50 caracteres'));
        setIsSubmitDisabled(true);
        return;
      }
      setAttach(files);
      setPreloadURL(URL.createObjectURL(files[0]));
    }
    setIsSubmitDisabled(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getLessonContentData = async () => {
    try {
      const response = await LessonContentData.getLessonContent({
        id_lesson: lesson.id_lesson,
      });

      setLessonContents(response.data.contents);
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(
    (_) => {
      if (
        [videoModal, fileModal, htmlModal, audioContentModal].every(
          (_) => _ == false
        )
      ) {
        setLessonUpdate(null);
      }
    },
    [videoModal, fileModal, htmlModal, audioContentModal]
  );

  useEffect(() => {
    getLessonContentData();
  }, []);

  useEffect(() => {
    if (lessonUpdate) {
      switch (lessonUpdate.type) {
        case 1:
          // console.log('video');
          handleOpenVideo(lessonUpdate.id_lesson);
          setTimeout(() => {
            setValue([
              { title: lessonUpdate.name },
              { description: lessonUpdate.description },
            ]);
          }, 500);
          break;

        case 2:
          // console.log('arquivo');
          handleOpenFile(lessonUpdate.id_lesson);
          setTimeout(() => {
            setAllowPdfDownload(!!lessonUpdate.allow_download);
            setValue([
              { title: lessonUpdate.name },
              { description: lessonUpdate.description },
            ]);
          }, 500);
          break;

        case 3:
          const contentBlock = htmlToDraft(lessonUpdate.content);
          if (contentBlock) {
            handleOpenHtmlContent(lessonUpdate.id_lesson);
            setContentHTML(
              EditorState.createWithContent(
                ContentState.createFromBlockArray(contentBlock.contentBlocks)
              )
            );
            setTimeout(() => {
              setValue([
                { title: lessonUpdate.name },
                { description: lessonUpdate.description },
              ]);
            }, 500);
          }
          break;

        case 4:
          // console.log('transmissão ao vivo', lessonUpdate);
          break;

        case 5:
          // console.log('audio', lessonUpdate);
          break;

        case 6:
          // console.log('pesquisas', lessonUpdate);
          break;

        case 7:
          // console.log('avaliação', lessonUpdate);
          break;

        case 8:
          // console.log('iframes', lessonUpdate);
          handleOpenEmbedContent(lessonUpdate.id_lesson);
          setTimeout(() => {
            setValue([
              { title: lessonUpdate.name },
              { description: lessonUpdate.description },
              { url: lessonUpdate.content },
            ]);
          }, 500);
          break;
        case 9:
          handleOpenEmbedInteractContent(lessonUpdate.id_lesson);
          setTimeout(() => {
            setValue([
              { title: lessonUpdate.name },
              { description: lessonUpdate.description },
              { url: lessonUpdate.content },
            ]);
          }, 500);
          break;
        default:
      }
    }
  }, [lessonUpdate]);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      className={classes.card}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexDirection: 'column',
        }}
      >
        <div className={classes.headerCard}>
          <Grid
            sm={6}
            style={{ paddingTop: 9, display: 'flex', alignItems: 'center' }}
          >
            <IconButton size="small" className={classes.title}>
              <OndemandVideoIcon fontSize="inherit" />
            </IconButton>
            <span className={classes.title} style={{ marginLeft: 10 }}>
              {lesson.title}
            </span>
          </Grid>

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            wrap="nowrap"
            className={classes.paddingClass}
          >
            <Grid
              item
              md={1}
              sm={1}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {is_owner ? (
                <>
                  {lessonContents.length === 0 ? (
                    <IconButton
                      size="small"
                      aria-describedby={id}
                      onClick={handleClick}
                    >
                      <Apps fontSize="inherit" />
                      <span className={classes.contents}>
                        {t('tables:Classroom.content_column')}
                      </span>
                    </IconButton>
                  ) : null}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <Box p={1}>
                      <Grid container className={classes.box}>
                        <Grid container item>
                          <Grid item xs={4} className={classes.gridIcon}>
                            <IconButton
                              size="medium"
                              aria-describedby={id}
                              onClick={() => handleOpenVideo(lesson.id_lesson)}
                            >
                              <OndemandVideo fontSize="inherit" />
                            </IconButton>
                            <MuiTypography variant="caption">
                              {t('label:Lesson.label_video')}
                            </MuiTypography>
                          </Grid>
                          <Grid item xs={4} className={classes.gridIcon}>
                            <IconButton
                              size="medium"
                              aria-describedby={id}
                              onClick={() => handleOpenFile(lesson.id_lesson)}
                            >
                              <Folder fontSize="inherit" />
                            </IconButton>
                            <MuiTypography variant="caption">
                              {t('label:Lesson.label_archive')}
                            </MuiTypography>
                          </Grid>
                          <Grid item xs={4} className={classes.gridIcon}>
                            <IconButton
                              size="medium"
                              aria-describedby={id}
                              onClick={() =>
                                handleOpenHtmlContent(lesson.id_lesson)
                              }
                            >
                              <MenuBook fontSize="inherit" />
                            </IconButton>
                            <MuiTypography variant="caption">
                              {t('label:Lesson.label_edit')}
                            </MuiTypography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          {/* <Grid item xs={4} className={classes.gridIcon}>
                            <IconButton
                              size="medium"
                              aria-describedby={id}
                              className={classes.notAllowed}
                            >
                              <Duo fontSize="inherit" />
                            </IconButton>
                            <MuiTypography variant="caption">
                              {t('label:Lesson.label_stream')}
                            </MuiTypography>
                          </Grid> */}
                          <Grid item xs={4} className={classes.gridIcon}>
                            <IconButton
                              size="medium"
                              aria-describedby={id}
                              onClick={() => {
                                setCurrentLessonContent(lesson.id_lesson);
                                setAudioContentModal(true);
                              }}
                            >
                              <HeadsetMic fontSize="inherit" />
                            </IconButton>
                            <MuiTypography variant="caption">
                              {t('label:Lesson.label_audio')}
                            </MuiTypography>
                          </Grid>
                          <Grid item xs={4} className={classes.gridIcon}>
                            <IconButton size="medium" aria-describedby={id}>
                              <WebAsset
                                size="medium"
                                aria-describedby={id}
                                onClick={() => {
                                  setCurrentLessonContent(lesson.id_lesson);
                                  setEmbedContentModal(true);
                                }}
                              />
                            </IconButton>
                            <MuiTypography variant="caption">
                              {t('label:Lesson.label_iframe')}
                            </MuiTypography>
                          </Grid>
                          {/* iframe interativo LMS */}

                          <Grid item xs={4} className={classes.gridIcon}>
                            <IconButton size="medium" aria-describedby={id}>
                              <FolderOpen
                                size="medium"
                                aria-describedby={id}
                                onClick={() => {
                                  setCurrentLessonContent(lesson.id_lesson);
                                  setInteractiveContentModal(true);
                                  handleIconClick();
                                }}
                              />
                            </IconButton>
                            <MuiTypography variant="caption">
                              {t('label:Lesson.label_interactive')}
                            </MuiTypography>
                          </Grid>

                        </Grid>

                        <Grid container item>
                          <Grid item xs={4} className={classes.gridIcon}>
                            <IconButton
                              size="medium"
                              aria-describedby={id}
                              onClick={() => {
                                handleAssessmentRoute(lesson);
                              }}
                            >
                              <AssignmentTurnedIn fontSize="inherit" />
                            </IconButton>
                            <MuiTypography variant="caption">
                              {t('label:Lesson.label_assessment')}
                            </MuiTypography>
                          </Grid>
                          {/* <Grid item xs={4} className={classes.gridIcon}>
                            <IconButton
                              size="medium"
                              aria-describedby={id}
                              className={classes.notAllowed}
                            >
                              <Assignment fontSize="inherit" />
                            </IconButton>
                            <MuiTypography variant="caption">
                              {t('label:Lesson.label_search')}
                            </MuiTypography>
                          </Grid> */}

                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                </>
              ) : null}
            </Grid>

            <Grid
              item
              md={1}
              sm={1}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {is_owner ? (
                <>
                  <IconButton
                    size="small"
                    onClick={(event) => setMenuLesson(event.currentTarget)}
                  >
                    <MoreVert fontSize="inherit" />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={menuLesson}
                    keepMounted
                    open={Boolean(menuLesson)}
                    onClose={() => setMenuLesson(null)}
                  >
                    <MenuItem
                      onClick={() => {
                        setMenuLesson(null);
                        setModalStatus(true);
                      }}
                    >
                      {t('tables:group.role_delete')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push(`/Lessons/${lesson.id_lesson}/edit`);
                      }}
                    >
                      {t('tables:group.role_put')}
                    </MenuItem>
                  </Menu>
                </>
              ) : null}

              {/* <IconButton
                size="small"
                onClick={() => {
                  history.push(
                    `/course/${company_course_id}/learn/${lesson.id_lesson}`
                  );
                }}
              >
                <Visibility fontSize="inherit" />
              </IconButton> */}
            </Grid>
          </Grid>
        </div>

        {!loading &&
          lessonContents.map((elem) => {
            return (
              <ContentCard
                key={elem.id}
                className={classes.card}
                is_owner={is_owner}
                lesson={elem}
                deleteContent={() => functionContentDelete(elem.id)}
                setModalUpdate={setLessonUpdate}
              />
            );
          })}
      </div>
      {provided.placeholder}

      {is_owner ? (
        <>
          <HandleDelete
            closeModal={setModalStatus}
            isOpen={modalStatus}
            route={`lessons/${lesson.id_lesson}`}
            functionDelete={functionDelete}
          />
          <UploadModal
            modalTitle={t('form:Lessons.modal_title_vimeo')}
            isOpen={videoModal}
            ModalClose={setVideoModal}
            buttonTitle={t('form:Lessons.modal_button_title')}
            closeModal={setVideoModal}
            buttonAction={fileUploadVimeo}
            handleSubmit={handleSubmit}
          >
            {preloadURL !== null && (
              <video
                src={preloadURL}
                controls
                preload="true"
                width="530"
                style={{ margin: 10 }}
              />
            )}

            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_title')}
                  helperText={
                    errors.title?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Lessons.input_title'),
                    })
                  }
                  name="title"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.title}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_description')}
                  name="description"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: false })}
                  error={!!errors.description}
                />
              </Grid>

              <DropzoneArea
                getPreviewIcon={handlePreviewIcon}
                filesLimit={1}
                maxFileSize={5368709120}
                acceptedFiles={['video/*']}
                dropzoneText={t('form:Lessons.modal_text_dragzone_vimeo')}
                onChange={(files) => handleChangeVimeo(files)}
                submitButtonText={t('form:Lessons.modal_button_title')}
                onDelete={() => setPreloadURL(null)}
              />
            </Grid>
          </UploadModal>
          <UploadModal
            modalTitle={t('form:Lessons.modal_title_file')}
            isOpen={fileModal}
            ModalClose={setFileModal}
            buttonTitle={t('form:Lessons.modal_button_title')}
            closeModal={setFileModal}
            buttonAction={fileUpload}
            handleSubmit={handleSubmit}
            disabled={isSubmitDisabled}
          >
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_title')}
                  helperText={
                    errors.title?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Lessons.input_title'),
                    })
                  }
                  name="title"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.title}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_description')}
                  name="description"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: false })}
                  error={!!errors.description}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.topButtonSpacing}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={allowPdfDownload}
                        onChange={handleChangeAllowPdfDownload}
                        name="allowPdfDownload"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={t('form:Lessons.input_allow_download')}
                  />
                </FormControl>
              </Grid>

              <DropzoneArea
                getPreviewIcon={handlePreviewIcon}
                filesLimit={1}
                maxFileSize={5368709120}
                dropzoneText={t('form:Lessons.modal_text_dragzone_vimeo')}
                onChange={(files) => handleChangeVimeo(files)}
                submitButtonText={t('form:Lessons.modal_button_title')}
                onDelete={() => setPreloadURL(null)}
              />

              {lessonUpdate && (
                <span>
                  <strong>Arquivo atual:</strong>{' '}
                  <a href={lessonUpdate?.file?.url} target="_blank" download>
                    {lessonUpdate?.file?.name}
                  </a>
                </span>
              )}
            </Grid>
          </UploadModal>
          <UploadModal
            modalTitle={t('form:Lessons.modal_title_html')}
            isOpen={htmlModal}
            ModalClose={setHtmlModal}
            buttonTitle={t('form:Lessons.modal_button_title_html')}
            closeModal={setHtmlModal}
            buttonAction={htmlContentUpload}
            handleSubmit={handleSubmit}
          >
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_title')}
                  helperText={
                    errors.title?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Lessons.input_title'),
                    })
                  }
                  name="title"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.title}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_description')}
                  name="description"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: false })}
                  error={!!errors.description}
                />
              </Grid>
            </Grid>
            <Editor
              editorStyle={{
                border: '1px solid #C0C0C0',
                borderRadius: '4px',
                height: '17rem',
              }}
              editorState={contentHTML}
              toolbarClassName="rdw-storybook-toolbar"
              wrapperClassName="rdw-storybook-wrapper"
              editorClassName="rdw-storybook-editor"
              onEditorStateChange={(value) => setContentHTML(value)}
              toolbar={{
                options: ['inline', 'list', 'link', 'remove', 'history'],
              }}
            />
          </UploadModal>
          <UploadModal
            modalTitle={t('form:Lessons.modal_title_file')}
            isOpen={audioContentModal}
            ModalClose={setAudioContentModal}
            buttonTitle={t('form:Lessons.modal_button_title')}
            closeModal={setAudioContentModal}
            buttonAction={audioContentUpload}
            handleSubmit={handleSubmit}
            disabled={isSubmitDisabled}
          >
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_title')}
                  helperText={
                    errors.title?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Lessons.input_title'),
                    })
                  }
                  name="title"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.title}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_description')}
                  name="description"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: false })}
                  error={!!errors.description}
                />
              </Grid>
            </Grid>

            <DropzoneArea
              getPreviewIcon={handlePreviewIcon}
              filesLimit={1}
              maxFileSize={5368709120}
              dropzoneText={t('form:Lessons.modal_text_dragzone_vimeo')}
              onChange={(files) => handleChangeVimeo(files)}
              submitButtonText={t('form:Lessons.modal_button_title')}
              onDelete={() => setPreloadURL(null)}
            />
          </UploadModal>
          <UploadModal
            modalTitle={t('form:Lessons.modal_title_iframe')}
            isOpen={embedContentModal}
            ModalClose={setEmbedContentModal}
            buttonTitle={t('form:Lessons.modal_button_title_html')}
            closeModal={setEmbedContentModal}
            buttonAction={embedContentUpload}
            handleSubmit={handleSubmit}
          >
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_title')}
                  helperText={
                    errors.title?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Lessons.input_title'),
                    })
                  }
                  name="title"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.title}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_description')}
                  name="description"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: false })}
                  error={!!errors.description}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_url')}
                  helperText={
                    errors.title?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Lessons.input_url'),
                    })
                  }
                  name="url"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.url}
                />
              </Grid>
              <Grid className={classes.info}>
                <Box style={{ display: 'flex' }}>
                  <InfoOutlined style={{ height: 16, marginTop: 1 }} />
                  <Typography>{t('message:iframe.info_embed_link')}</Typography>
                </Box>
                <IconButton title="Copiar exemplo" onClick={handleCopyExSite}>
                  <FileCopyOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </UploadModal>

          <UploadModal
            modalTitle={t('form:Lessons.modal_title_interact')}
            isOpen={interactiveContentModal}
            ModalClose={() => setInteractiveContentModal(false)}
            buttonTitle={t('form:Lessons.modal_button_title')}
            closeModal={() => setInteractiveContentModal(false)}
            buttonAction={embedContentInteractUpload}
            handleSubmit={handleSubmit}
          >
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_title')}
                  helperText={
                    errors.title?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Lessons.input_title'),
                    })
                  }
                  name="title"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.title}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_description')}
                  name="description"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: false })}
                  error={!!errors.description}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Lessons.input_url')}
                  helperText={
                    errors.title?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Lessons.input_url'),
                    })
                  }
                  name="url"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.url}
                />
              </Grid>
              <Grid className={classes.info}>
                <Box style={{ display: 'flex' }}>
                  <InfoOutlined style={{ height: 16, marginTop: 1 }} />
                  <Typography>{t('message:iframe.info_embed_link')}</Typography>
                </Box>
                <IconButton title="Copiar exemplo" onClick={handleCopyExSite}>
                  <FileCopyOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </UploadModal>
        </>
      ) : null}
    </div>
  );
};
