import querystring from 'querystring';
import api from '../api';

class DataServiceTrail {
  addTrail(data) {
    return api.post('trails', data);
  }

  getTrails(params) {
    const url = 'trails?' + querystring.stringify(params);
    return api.get(url);
  }

  getTrailById(id) {
    return api.get(`trails/${id}`);
  }

  updateTrail(id, data) {
    return api.put(`trails/${id}`, data);
  }

  getStudentAvailableTrails(id_company) {
    const url = `company-trail/student-content/${id_company}`;
    return api.get(url);
  }

  getTrailDetailsById(id) {
    const url = `company-trail/trail-courses/${id}`;
    return api.get(url);
  }

  enrollTrail(data) {
    return api.post('trail-user', data);
  }

  enrollTrailBulk(data) {
    return api.post('trails-user', data);
  }

  getTrailUser(id) {
    return api.get(`trail-user/${id}`);
  }
}

export default new DataServiceTrail();
